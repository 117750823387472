.App {
  /* text-align: center; */
  font-weight: 500;
}

.fab {
  max-width: 600px;
	position: fixed;
	align-items: center;
	justify-content: center;
	text-align: center;
	bottom: 32px;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: #FFFFFF;
	background-color: #70A375;
	border-radius: 8px;
	font-size: 1.25rem;
	font-weight: 700;
	white-space: nowrap;
	text-decoration: none;
	height: 3rem;
	line-height: 3rem;
}

.fab-mobile {
  max-width: 90%;
  font-size: 0.75rem;
	height: 3rem;
	line-height: 3rem;
}

/* .notion-column > .notion-callout {
  border: 0.5px solid #C0C0C0;
} */

.notion-callout {
  align-items: flex-start;
}

.nav {
  background-color: #D0D0D0;
}

.notion-page-header {
  display: none;
}

.notion-page-offset {
  margin-top: 72px
}

.notion b {
  font-weight: 700;
}

.notion-column {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0.5px solid #C0C0C0;
  border-radius: 3px;
}

.logo {
  height: 24px;
}

.notion-title {
  display: none;
}

.navbar {
  border-bottom: 0.5px solid #C0C0C0;
}